import * as React from "react"
import {Link} from "gatsby"
import {StaticImage} from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import * as styles from "../components/style.css"
import {useState} from "react";
import designGif from '../images/product/design-certificate.gif'
import groupGif from '../images/product/create-group.gif'
import certificateGIF from '../images/product/publish-certificate.gif'
import {MaterialAdd} from "../components/material-icons";

const FaqsPage = () => {

    const faqs = [
        {
            "question": "What is Hyperstack?",
            "answer": "Hyperstack is a blockchain-based digital credentialing platform that enables and automates the delivery of digital certificates, badges, transcripts, diplomas and other credentials. Founded in 2018, Hyperstack aims to let its clients create, manage, issue, and share online certificates as easily and quickly as possible.",
        },
        {
            "question": "How Does Hyperstack work?",
            "answer": "Hyperstack allows you to create and manage digital credentials, automating the generation process and validates its authenticity with blockchain technology. Hyperstack uses the Hedera Hashgraph to store and verify credentials.",
        },
        {
            "question": "What is the Hedera hashgraph?",
            "answer": "The Hedera Hashgraph is an advanced blockchain infrastructure for enterprises that enables the no-trust authenticity requirements for Hyperstack.",
        },
        {
            "question": "What is a smart digital certificate?",
            "answer": "A smart digital certificate, unlike a PDF certificate, is interactive, shareable, verifiable and legitimate, and gives credibility to both the recipient as well as the issuing authority.",
        },
        {
            "question": "How is it different from traditional PDF certificates?",
            "answer": "PDF Certificates hold little to no value as they are easy to fake or be tampered with, and validation is impossible, whereas smart digital certificates issued by Hyperstack can be verified and issued in bulk.",
        },
    ]


    const [faqShown, setFaqShown] = useState('');

    const toggleFaq = (id) => {
        setFaqShown(faqShown === id ? '0':id);
    };

    return (<Layout>
        <section className={"section--row"}>
            <h1>Frequently asked<br/>
                questions, <span className={"text-blue"}>answered</span>.</h1>

            <br/>

            {(faqs.map((faq, id) => {
                return (
                    <div className={"faq"} key={id}>
                        <div className={"question"} onClick={() => toggleFaq(id)}>
                            <h4>{faq.question}</h4>
                            <MaterialAdd color={"#666"} size={"18px"}/>
                        </div>
                        <div className={faqShown === id ? "answer" : "answer-hide"}>
                            <p>{faq.answer}</p>
                        </div>
                    </div>
                )
            }))}

        </section>


    </Layout>)

}
export const Head = () => <Seo
    title="FAQs - Hyperstack"
    description="Find the most common questions about Hyperstack's digital credentials, certificates and badges"
/>

export default FaqsPage
